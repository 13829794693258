import React from 'react'
import './blockImage.scss'

//Imgs added in body (BlockContent)

const Image = (props: any) => {
  return (
    <div className="blockImage">
      <img src={props.src} alt="" />
    </div>
  )
}

export default Image

import React from 'react'
import { urlFor } from './../templates/imgBuilder'
import BlockImage from './BlockImage'
import BlockContent from '@sanity/block-content-to-react'

export const serializers = {
  types: {
    //Handels images added in the body (BlockContent)
    image: (props) => {
      const imgBuilder = urlFor()
      const src = imgBuilder.image(props.node.asset)
      return <BlockImage src={src} />
    },
  },
}

export const removeP = {
  types: {
    block: (props) => {
      const { style = 'normal' } = props.node

      if (style === 'normal') {
        return <>{props.children}</> //Removes the <p>-wrapper.
      }

      //Fall back to default handling
      return BlockContent.defaultSerializers.types.block(props)
    },
  },
}

/**
 *  For more information, see:
 *  https://www.gatsbyjs.com/docs/use-static-query/ &
 *  https://codesandbox.io/s/m4q82k8ry9
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
  keywords: PropTypes.string,
}

function SEO({ description, lang, keywords, title }) {
  const metaDescription = description
  const defaultTitle = 'Nira Dynamics'

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ? title : defaultTitle}
      titleTemplate={title ? `%s | ${defaultTitle}` : defaultTitle}
    >
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={keywords} />

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"
      />
      <meta name="theme-color" content="#9194af" />
      <link rel="icon" href="/favicon.png" />
      <link rel="icon" href="/favicon.png" sizes="32x32" />
      <link rel="icon" href="/favicon-64x64.png" sizes="64x64" />
      <link rel="icon" href="/favicon-96x96.png" sizes="96x96" />
      <link rel="icon" href="/favicon-192x192.png" sizes="192x192" />
      <link rel="apple-touch-icon" href="/favicon-300x300.png" />

      <meta name="author" content="Nira Dynamics" />

      <meta name="twitter:card" content="summary" />
      <meta property="og:url" content="https://roads.niradynamics.se/" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content="" />
      <meta property="og:image:alt" content="Nira Dynamics" />
    </Helmet>
  )
}

export default SEO

import React, { useState, useEffect } from 'react'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import CookieBar from './../../misc/CookieBar'

export interface LayoutProps {
  children: any
  localeCode: string
}

const Layout = (props: LayoutProps) => {
  const [showCookieBar, setShowCookieBar] = useState(false)

  // This runs when the page is loaded.
  useEffect(() => {
    if (localStorage.acceptedCookies !== 'true') {
      setShowCookieBar(true)
    }
  }, [])

  const children = props.children
  return (
    <>
      {children}
      {showCookieBar && <CookieBar localeCode={props.localeCode} />}
    </>
  )
}

export default Layout

import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import { serializers, removeP } from '../misc/serializers'
import './cookiebar.scss'

export interface CookieBarProps {
  localeCode: string
}

const acceptCookies = () => {
  localStorage.acceptedCookies = 'true'
  var e = document.getElementById('cookiebar')
  e.classList.replace('visible', 'hidden')
}

const AcceptCookies = (props: CookieBarProps) => {
  const data = useStaticQuery(graphql`
    {
      sanityStaticTextCookies {
        ...CookiesFragment
      }
    }
  `)

  //To match the translation in the grapql request
  const localeCodeBody =
    '_raw' + props.localeCode.charAt(0).toUpperCase() + props.localeCode.slice(1)

  const defaultCookieInformation = data.sanityStaticTextCookies.body['_rawEn']
  const { title, body, termsAndConditionsLink, buttonText } = data.sanityStaticTextCookies

  return (
    <div
      className={
        'cookiebar ' + (localStorage.acceptedCookies === 'true' ? 'hidden' : 'visible')
      }
      id="cookiebar"
    >
      <div>
        <div>
          <h6>{title[props.localeCode] ? title[props.localeCode] : title['en']}</h6>
          <div>
            <BlockContent
              blocks={
                body[localeCodeBody] ? body[localeCodeBody] : defaultCookieInformation
              }
              serializers={{
                types: { block: removeP.types.block },
              }}
            />
            <Link to="/terms">
              {' '}
              {termsAndConditionsLink[props.localeCode]
                ? termsAndConditionsLink[props.localeCode]
                : termsAndConditionsLink['en']}
            </Link>
          </div>
        </div>

        <div className="actions">
          <button className="button" onClick={() => acceptCookies()}>
            {' '}
            {buttonText[props.localeCode]
              ? buttonText[props.localeCode]
              : buttonText['en']}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AcceptCookies
